import React from 'react';
import './App.css';
import { NavBar } from './components/NavBar';
import { FullBanner } from './components/FullBanner';

function App() {
  return (
    <div className="app-container d-flex flex-column h-100">
      <NavBar/>
      <FullBanner/>
    </div>
  );
}

export default App;
