import React from 'react';
import ImgCentro from '../media/PRELANDIG-SUC-CENTRO.webp'
import ImgGuemes from '../media/PRELANDING-SUC-GUEMES.webp'

export const FullBanner = () => {
    return (
        <div id="full-banner" className="h-100">
            <div className="row h-100">
                <div className="col-12 col-lg-6 px-0">
                    <a href="https://www.beberinmobiliariacentro.com.ar" target="_blank" rel="noopener noreferrer" className="link w-100 h-100 d-flex justify-content-center align-items-center position-relative">
                        <img src={ImgCentro} alt="Beber Inmobiliaria Centro" className="img-banner position-absolute w-100 h-100 object-fit-cover z-0"/>
                        <div className="d-flex flex-column align-items-center">
                            <h4 className="subtitle z-1">SUCURSAL</h4>
                            <h1 className="title z-1 text-uppercase mb-3">CENTRO</h1>
                            <button className="cta z-1 text-uppercase">VISITAR</button>
                        </div>
                    </a>
                </div>
                <div className="col-12 col-lg-6 px-0">
                    <a href="https://www.beberinmobiliariaguemes.com.ar" target="_blank" rel="noopener noreferrer" className="link w-100 h-100 d-flex justify-content-center align-items-center position-relative">
                        <img src={ImgGuemes} alt="Beber Inmobiliaria Güemes" className="img-banner position-absolute w-100 h-100 object-fit-cover z-0"/>
                        <div className="d-flex flex-column align-items-center">
                            <h4 className="subtitle z-1">SUCURSAL</h4>
                            <h1 className="title z-1 text-uppercase mb-3">GÜEMES</h1>
                            <button className="cta z-1 text-uppercase">VISITAR</button>
                        </div>
                    </a>
                </div>
            </div>
        </div>
    )
};